import { useMutation, useReactiveVar } from '@apollo/client';
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material/Select';

import useWindowSize from '@hooks/useWindowSize';
import { CREATE_VISITOR_RESERVATION } from '@graphql/mutations';
import { Mutation, WorkSite } from '@graphql/types';
import { siteOptionVar } from '@store/index';

import ReservationFormPC from '@components/feature/Reservation/ReservationFormPC';
import ReservationFormMobile from '@components/feature/Reservation/ReservationFormMobile';

export type infoTypes = {
    site: string;
    siteUnique: string;
    type: string;
    company: string;
    name: string;
    phone1: string;
    phone2: string;
    phone3: string;
    path: string;
    staffTeam: string;
    staffName: string;
    team: string;
    carLicenseNum: string;
    hour: string;
    minute: string;
    reservedDate: string;
    reservedHour: string;
    reservedMinute: string;
};

const Reservation = () => {
    const navigate = useNavigate();
    const { width } = useWindowSize();
    const siteList: WorkSite[] = useReactiveVar(siteOptionVar);
    // 서약서 모달
    const [openVow, setOpenVow] = useState<boolean>(false);
    // 약관 토글
    const [agree, setAgree] = useState<boolean>(false);
    const [info, setInfo] = useState<infoTypes>({
        site: '',
        siteUnique: '',
        type: 'visitor',
        company: '',
        name: '',
        phone1: '010',
        phone2: '',
        phone3: '',
        path: 'walk',
        staffTeam: '',
        staffName: '',
        team: '',
        carLicenseNum: '',
        hour: '', // number. 빈값 0
        minute: '', // number. 빈값 0
        reservedDate: new Date().toISOString(),
        reservedHour: '',
        reservedMinute: '',
    });

    // mutation 리졸버
    const [createVisitorReservation] = useMutation<
        Pick<Mutation, 'createVisitorReservation'>
    >(CREATE_VISITOR_RESERVATION);

    // 정보 업데이트
    const infoUpdateHandler = useCallback(
        (
            e:
                | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
                | SelectChangeEvent,
        ) => {
            const { value, name } = e.target;
            const { maxLength } = e.target as HTMLInputElement;
            setInfo((prev) => ({ ...prev, [name]: value.slice(0, maxLength) }));
            if (name === 'siteUnique') {
                const selectedInfo = siteList.filter(
                    (info) => info.ws_unique === value,
                );
                setInfo((prev) => ({
                    ...prev,
                    site: selectedInfo[0].ws_name as string,
                }));
            }
        },
        [siteList],
    );

    // 방문자 등록
    const submitHandler = useCallback(() => {
        const {
            siteUnique,
            company,
            name,
            phone1,
            phone2,
            phone3,
            path,
            staffTeam,
            staffName,
            carLicenseNum,
            hour,
            minute,
            reservedDate,
            reservedHour,
            reservedMinute,
        } = info;
        const year = new Date(reservedDate).getFullYear();
        const month = new Date(reservedDate).getMonth();
        const date = new Date(reservedDate).getDate();

        createVisitorReservation({
            variables: {
                visitDate: new Date(
                    year,
                    month,
                    date,
                    Number(reservedHour),
                    Number(reservedMinute),
                ).toISOString(),
                workSiteUnique: siteUnique,
                companyName: company,
                name: name,
                cellPhone: `${phone1}${phone2}${phone3}`,
                visitPath: path,
                carLicenseNumber: carLicenseNum,
                hour: hour ? Number(hour) : 0,
                min: minute ? Number(minute) : 0,
                managerTeam: staffTeam,
                managerName: staffName,
            },
            onCompleted: (data) => {
                setOpenVow(false);
                navigate('/reservation/completion');
            },
            onError: (err) => {
                console.log(err);
            },
        });
    }, [createVisitorReservation, info, navigate]);

    return (
        <>
            {width < 1280 && (
                <ReservationFormMobile
                    openVow={openVow}
                    agree={agree}
                    info={info}
                    setOpenVow={setOpenVow}
                    setAgree={setAgree}
                    setInfo={setInfo}
                    infoUpdateHandler={infoUpdateHandler}
                    submitHandler={submitHandler}
                />
            )}
            {width >= 1280 && (
                <ReservationFormPC
                    openVow={openVow}
                    agree={agree}
                    info={info}
                    setOpenVow={setOpenVow}
                    setAgree={setAgree}
                    setInfo={setInfo}
                    infoUpdateHandler={infoUpdateHandler}
                    submitHandler={submitHandler}
                />
            )}
        </>
    );
};

export default Reservation;
