import { gql } from '@apollo/client';

export const SEE_WORK_SITE = gql`
    query SeeWorkSite($type: Int!) {
        seeWorkSite(type: $type) {
            ws_id
            ws_open
            ws_unique
            ws_name
            floors
            ws_address
            ws_addressDetail
            ws_updatedAt
        }
    }
`;
