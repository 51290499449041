import { Route, Routes } from 'react-router-dom';

import useWindowSize from '@hooks/useWindowSize';

import Main from '@pages/Main';
import RegisterVisitor from '@pages/RegisterVisitor';
import RegisterParking from '@pages/RegisterParking';
import NotFound from '@pages/NotFound';
import Reservation from '@pages/Reservation';
import ReservationComplete from '@pages/ReservationComplete';

const Router = () => {
    const { width } = useWindowSize();
    return (
        <Routes>
            {width < 1280 && <Route path="/*" element={<Main />} />}
            {width >= 1280 && <Route path="/" element={<Main />} />}
            {width >= 1280 && <Route path="/*" element={<NotFound />} />}
            <Route path="/visitor" element={<RegisterVisitor />} />
            <Route path="/parking" element={<RegisterParking />} />
            <Route path="/reservation" element={<Reservation />} />
            <Route
                path="/reservation/completion"
                element={<ReservationComplete />}
            />
        </Routes>
    );
};

export default Router;
