import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import 'react-toastify/dist/ReactToastify.css';

import MusinsaLight from './fonts/Musinsa/musinsa-Light.ttf';
import MusinsaMedium from './fonts/Musinsa/musinsa-Medium.ttf';
import MusinsaBold from './fonts/Musinsa/musinsa-Bold.ttf';
import AppleSDGothicNeoHeavy from './fonts/AppleSDGothic/AppleSDGothicNeoH.ttf';
import AppleSDGothicNeoExtraBold from './fonts/AppleSDGothic/AppleSDGothicNeoEB.ttf';
import AppleSDGothicNeoBold from './fonts/AppleSDGothic/AppleSDGothicNeoB.ttf';
import AppleSDGothicNeoMedium from './fonts/AppleSDGothic/AppleSDGothicNeoM.ttf';
import AppleSDGothicNeoRegular from './fonts/AppleSDGothic/AppleSDGothicNeoR.ttf';

export default createGlobalStyle`
  ${reset}
  
  @font-face {
    font-family: MusinsaLight;
    src: url(${MusinsaLight});
  }

  @font-face {
    font-family: MusinsaMedium;
    src: url(${MusinsaMedium});
  }

  @font-face {
    font-family: MusinsaBold;
    src: url(${MusinsaBold});
  }

  @font-face {
    font-family: AppleSDGothicNeoHeavy; //900
    src: url(${AppleSDGothicNeoHeavy});
  }

  @font-face {
    font-family: AppleSDGothicNeoExtraBold; //800
    src: url(${AppleSDGothicNeoExtraBold});
  }

  @font-face {
    font-family: AppleSDGothicNeoBold; //700
    src: url(${AppleSDGothicNeoBold});
  }

  @font-face {
    font-family: AppleSDGothicNeoMedium; //500
    src: url(${AppleSDGothicNeoMedium});
  }

  @font-face {
    font-family: AppleSDGothicNeoRegular; //400
    src: url(${AppleSDGothicNeoRegular});
  }

  * {
    box-sizing: border-box;
    &::-webkit-scrollbar {
        width: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.colors.lightGrayBorder};
        border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
        background-color: #fafafa;
        border-radius: 4px;
    }
  }

  body {
    width: 100%;
    min-width: 375px;
    height: 100%;
    font-size: 15px;
    font-family: "MusinsaMedium", "AppleSDGothicNeoRegular", sans-serif;
    background-color: ${(props) => props.theme.colors.bgColor};
    color: ${(props) => props.theme.colors.fontColor};
    transition: all .2s;
  }

  a {
    color: ${(props) => props.theme.colors.fontColor};
    text-decoration: none;
  }
  ul, ol{
    list-style: none;
  }

  textarea {
    resize: none;
  }

  input,
  textarea {
    font-size: 14px;
    border: none;
    outline: none;
    background-color: inherit;

    &::placeholder {
      font-size: 14px;
    }

    &:disabled {
      color: ${(props) => props.theme.colors.fontColor};
      background: inherit;
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }

  button {
    font-size: 15px;
    padding: 0;
    outline: none;
    border: none;
    background-color: ${(props) => props.theme.colors.bgColor};
    cursor: pointer;
  }

  select {
    font-size: 14px;
    border: none;
    outline: none;
    background-color: transparent;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
  }

  .Toastify__toast {
    z-index: 30;
  }

  // Mui Calendar
  .MuiStack-root{
    width: 100%;
    height: 50px;
    padding-top: 0 !important;
    overflow: unset;
    ${(props) => props.theme.device.pc}{
      width: 440px;
    }
  }

  .MuiOutlinedInput-notchedOutline{
    display: none;
  }

  // Mui Selectbox
  .MuiButtonBase-root{
    font-family: "AppleSDGothicNeoRegular", sans-serif !important;
    font-size: 15px !important;
  }

  .MuiPaper-root{
    box-shadow: 0px 1px 8px 0px rgba(50, 50,5 0, 0.4) !important;
    -webkit-box-shadow: 0px 1px 8px 0px rgba(50, 50, 50, 0.4) !important;
    -moz-box-shadow: 0px 1px 8px 0px rgba(50, 50, 50, 0.4) !important;
  }
`;
