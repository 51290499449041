import styled from 'styled-components';

const AgreementTerm = ({ site }: { site: string }) => {
    return (
        <AgreementDetail>
            <DetailText>
                {site ? site : ''}
                <br />
                방문하시는 분들의 주차 발급을 위해 수집해야 될 개인정보를 확인해
                주시기 바랍니다.
            </DetailText>
            <ListArea>
                <List>
                    1. 개인정보 수집 이용 목적: 차량 출입 관리 및 통제를 위한
                    차량 등록
                </List>
                <List>
                    2. 수집하는 개인정보 항목 : (필수) 방문자소속(법인명), 이름,
                    미팅 담당자 명
                </List>
                <List>
                    3. 개인정보의 보유 및 이용기간 : 이용목적 달성 후 30일 보관
                </List>
                <List>
                    4. 개인정보 수집에 대한 동의 거부를 하실 수 있습니다. (단,
                    거부 시 주차 이용에 제한이 있을 수 있습니다.)
                </List>
            </ListArea>
        </AgreementDetail>
    );
};

export default AgreementTerm;

const AgreementDetail = styled.div`
    margin: 18px 0 10px;
    padding: 15px;
    color: ${(props) => props.theme.colors.term};
    font-size: 13px;
    font-family: AppleSDGothicNeoRegular, sans-serif;
    word-break: keep-all;
    line-height: 1.3;
    background-color: ${(props) => props.theme.colors.lightGrayBorder};
    border: ${(props) => props.theme.colors.grayText} 1px solid;
    border-radius: 8px;
`;

const DetailText = styled.p`
    margin-bottom: 20px;
`;

const ListArea = styled.ul``;

const List = styled.li`
    font-family: AppleSDGothicNeoRegular, sans-serif;
`;
