import { useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { isDarkMode } from '@store';

export default function ResponsiveDatePickers({
    value,
    onChange,
}: {
    value: string | Date;
    onChange: (value: string | Date) => void;
}) {
    const currentTheme = useReactiveVar(isDarkMode);
    const theme = createTheme({
        palette: {
            mode: currentTheme ? 'dark' : 'light',
        },
    });

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={dayjs.locale('ko')}
            dateFormats={{ normalDate: 'M월 D일' }}
            localeText={{ okButtonLabel: '입력', cancelButtonLabel: '취소' }}
        >
            <ThemeProvider theme={theme}>
                <DemoContainer
                    components={[
                        'DatePicker',
                        'MobileDatePicker',
                        'DesktopDatePicker',
                        'StaticDatePicker',
                    ]}
                >
                    <StyledPicker
                        format="YYYY-MM-DD"
                        defaultValue={dayjs(value)}
                        onChange={(newValue) =>
                            onChange(dayjs(newValue).toISOString())
                        }
                        minDate={dayjs()}
                        autoFocus={false}
                    />
                </DemoContainer>
            </ThemeProvider>
        </LocalizationProvider>
    );
}

const StyledPicker = styled(MobileDatePicker)`
    width: 100%;
    height: 50px;
    ${(props) => props.theme.device.pc} {
        width: 440px;
    }
    .MuiInputBase-root {
        padding-right: 0 !important;
    }

    .MuiInputBase-input {
        height: 50px !important;
        box-sizing: border-box !important;
        cursor: pointer !important;
        border: ${(props) => props.theme.colors.lightGrayBorder} 1px solid !important;
        border-radius: 4px;
        color: ${(props) => props.theme.colors.fontColor} !important;

        &:hover,
        &:active,
        &:focus {
            border: ${(props) => props.theme.colors.fontColor} 1px solid !important;
        }
    }
`;
