import { useNavigate } from 'react-router-dom';

import * as R from './ReservationComplete.style';

const ReservationComplete = () => {
    const navigate = useNavigate();

    return (
        <R.Container>
            <R.Header>
                <R.Logo>MUSINSA</R.Logo>
                <R.SubTitle>리셉션</R.SubTitle>
            </R.Header>
            <R.Contents>
                <R.Title>방문 예약이 완료되었습니다.</R.Title>

                <R.HomeButton to="/">홈으로 이동</R.HomeButton>
            </R.Contents>
        </R.Container>
    );
};

export default ReservationComplete;
