import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';

import StyledModal from '@components/styled/StyledModal';
import StyledCheckbox from '@components/styled/StyledCheckbox';
import StyledButton from '@components/styled/StyledButton';

type VowModalTypes = {
    openVow: boolean;
    setOpenVow: React.Dispatch<React.SetStateAction<boolean>>;
    info: any;
    submitHandler: () => void;
};
const VowModal = ({
    openVow,
    setOpenVow,
    info,
    submitHandler,
}: VowModalTypes) => {
    const location = useLocation();
    const { pathname } = location;
    const [agreeVow, setAgreeVow] = useState(false);

    const closeBtnHandler = useCallback(() => {
        setOpenVow(false);
    }, [setOpenVow]);

    return (
        <StyledModal
            title="MUSINSA 정보보호 서약서"
            width="84%"
            isOpen={openVow}
            closeBtnHandler={closeBtnHandler}
        >
            <Container>
                <Contents>
                    <Text>
                        본인은 (주)무신사 (이하"회사"라칭함)의 사업을 수행함에
                        있어 사업 종료시까지 다음 사항을 준수할 것을 엄숙히
                        서약합니다.
                    </Text>
                    <ListWrapper>
                        <List>
                            회사의 보안구역 및 통제구역에 무단으로 출입하지
                            않겠습니다.
                        </List>
                        <List>
                            회사의 자산을 불법으로 유출, 변조하거나 훼손하지
                            않겠습니다.
                        </List>
                        <List>
                            허용되지 않은 정보자산에 접근을 시도하거나, 정보보호
                            기능을 우회하는 시도를 하지 않겠습니다.
                        </List>
                        <List>
                            업무상 취득한 회사 또는 제3자 소유의 정보를 회사의
                            승인 없이 누설하지 않겠습니다.
                        </List>
                        <List>
                            업무상 취득한 개인정보 등 개인정보에 대해 업무 목적
                            외 누설 및 이용을 금하며, 타인에게 제공하지
                            않겠습니다.
                        </List>
                        <List>
                            회사의 통신망을 이용하여 외부인 접근이 금지된 타
                            회사의 통신망 또는 시스템에 임의로 접속을 시도하지
                            않겠습니다.
                        </List>
                        <List>
                            회사의 정보통신(사내·외 이메일 및 범용메신저 등
                            일체의 대내·외적 정보통신 수단) 사용과 관련하여
                            정보보호를 위한 회사의 통제(사내·외 정보통신에 대한
                            열람, 내용의 기록, 보관 등 일체의 행위)에 대하여
                            이의없이 동의하겠습니다.
                        </List>
                        <List>
                            본인이 반입하는 정보처리기기(PC)반입 시 회사의
                            정보보안 솔루션을 설치하고 이를 우회하는 시도를 하지
                            않겠습니다.
                        </List>
                        <List>
                            사업 완료 시 또는 사업을 중도에 그만두게 된 경우,
                            본인이 보유하고 있는 모든 용역 관련 자료(노트북 및
                            보조기억매체 저장파일 포함)를 반납 및 파기하며,
                            복사본 등 용역사업 관련 자료를 보유하지 않겠습니다.
                        </List>
                        <List>
                            본 서약서에 작성된 개인정보는 서약인을 식별하기 위해
                            수집됨을 확인하였고, 개인정보 수집 및 회사의
                            개인정보 파기 정책에 동의합니다.
                        </List>
                    </ListWrapper>
                    <TodayDate>{dayjs().format('YYYY년 MM월 DD일')}</TodayDate>
                </Contents>
                <StyledCheckbox
                    id="agreeVow"
                    name="agreeVow"
                    label={`수행인 ${info.name}: 위 사항에 서약합니다.`}
                    checked={agreeVow}
                    onChange={() => setAgreeVow((prev) => !prev)}
                    margin={'0 0 12px'}
                />
                <StyledButton
                    title={
                        pathname.includes('reservation')
                            ? '방문 예약하기'
                            : '방문자 등록하기'
                    }
                    width={'100%'}
                    onClick={submitHandler}
                    margin={'30px 0 0'}
                    disabled={!agreeVow}
                />
            </Container>
        </StyledModal>
    );
};

export default VowModal;

const Container = styled.div`
    overflow-y: auto;
    max-height: calc(100svh - 120px);
    font-size: 14px;
`;

const Contents = styled.div`
    font-family: AppleSDGothicNeoRegular, sans-serif;
    line-height: 1.5;
    word-break: keep-all;
`;

const Text = styled.p`
    margin-bottom: 12px;
`;
const ListWrapper = styled.ul`
    padding-left: 20px;
    list-style: decimal;
`;

const List = styled.li`
    margin-bottom: 20px;
`;

const TodayDate = styled.span`
    display: block;
    margin-bottom: 20px;
    text-align: center;
`;
