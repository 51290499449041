import { makeVar } from '@apollo/client';
import { WorkSite } from '@graphql/types';
// 초기 상태
const userSiteName = '';
const initialSiteOption: WorkSite[] = [];

export const isDarkMode = makeVar(false);
export const userSiteNameValue = makeVar(userSiteName);
export const userSiteInfo = makeVar({});
export const siteOptionVar = makeVar(initialSiteOption);
